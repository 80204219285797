export const popularProducts = [
  {
    title: "Odin R900 Romaskine",
    price: "4499.00,-",
    url: "https://www.billigform.dk/andet/odin-r900-romaskine",
    image: "https://m2.apuls.dk/media/catalog/product/O/D/ODINR9_1.jpg",
  },
  {
    title: "Odin R650 Romaskine m. Kinomap",
    price: "3299.00,-",
    url: "https://www.billigform.dk/andet/odin-r650-romaskine-m-kinomap",
    image:
      "https://m2.apuls.dk/media/catalog/product/r/6/r650-2.jpg",
  },
  {
    title: "Odin T800 Løbebånd",
    price: "9499.00,-",
    url: "https://www.billigform.dk/andet/odin-t800-l%C3%B8beb%C3%A5nd-demo",
    image: "https://m2.apuls.dk/media/catalog/product/o/d/odin-t800-front_2.png",
  },
  {
    title: "Odin T200 Power walking gåbånd",
    price: "3499.00,-",
    url: "https://www.billigform.dk/andet/odin-t200-power-walking-g%C3%A5b%C3%A5nd",
    image: "https://m2.apuls.dk/media/catalog/product/0/_/0.jpg",
  },
];
