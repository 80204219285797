import * as React from "react";
import SEO from "../components/SEO";
import { popularProducts } from "../utils/popularProducts";
import Product from "../components/Product";

const IndexPage = () => {
  return (
    <>
      <SEO
        title="Det behøver ikke at være dyrt, at komme i form"
        description="Find øvelser og tilbud på træningsudstyr, sportstøj, kosttilskud med mere."
      />
      <div className="py-6">
        <h1 className="font-bold text-2xl">
          Populært træningsudstyr til hjemmet
        </h1>
        <div className="flex flex-wrap space-between">
          {popularProducts.map((pro) => (
            <Product
              popular
              key={pro.url}
              title={pro.title}
              image={pro.image}
              productPrice={pro.price}
              productLink={pro.url}
              productPath={pro.url}
            />
          ))}
        </div>
      </div>
      <div className="leading-8 p-4">
        <h2 className="text-2xl font-bold mt-4">Spar penge på kosttilskud</h2>
        <p>
          Det kan hurtigt blive dyrt, at bruge penge på kossttilskud. Alt efter
          forhandler og type, om det er vegansk, isolat eller kasein, kan der
          være flere hundrede kroners forskel, på eksempelvis proteinpulver.
          Samtidig er der forskel på kvaliteten, hvilket har stor indflydelse på
          effektiviteten. Derfor samler Billigform.dk udelukkende kossttilskud,
          fra anerkendte producenter.
          <br />
          Hos Billigform.dk finder du ikke kun tilbud på kosttilskud. Du får
          også mulighed for at finde frem, til det kosttilskud der passer bedst
          til dit behov. Her samler vi kosttilskud såsom whey protein, vegansk,
          kreatin, BCAA, ISO, kasein, snacks, og meget, meget mere.
          <br /> For at finde bedst frem til det ønskede kosttilskud, foreslår
          vi, at du anvender filteret under hver kategori.
        </p>
        <h2 className="text-2xl font-bold mt-4">
          Sådan får du mest ud af din træning
        </h2>{" "}
        <p>
          Træning er populært som aldrig før og særligt styrketræning er et hit.
          Det er ikke så sært, for det er sundt at holde sin krop i form og det
          er rart, at kigge sig i spejlet og være tilfreds med det man ser. Men
          styrketræning har masser af andre gavnlige effekter for dig. Du får
          ikke kun en flottere krop, slankere figur og en større muskelmasse ved
          at styrketræne. Styrketræning er nemlig også med til at forebygge, at
          du får hjertekar sygdomme, diabetes og andre livsstilssygdomme.
          Samtidig forbedrer du din kondition når du træner styrke. Dette er
          rigtig gode sideffekter af styrketræningen, men for de fleste af os,
          er det sikkert fedtforbrændingen og ikke mindst opbygningen af
          muskelmassen der er det vigtigste parameter, når vi ligger og sveder
          med vægtene i træningscentret. Muskelopbygning kræver sund kost Men
          god træning handler ikke kun om den energi du lægger i fitnesscentret.
          Hvis du vil have det optimale ud af din træning kræver det nemlig, at
          din krop får det rette brændstof i form af den kost du spiser. Består
          din kostpyramide primært af alkohol, junkfood og slik er det svært at
          skabe resultater i form af muskelmasse og styrke. Den optimale kost
          når du træner er derfor en sund og proteinrig kost, det er
          proteinerne, der er med til at opbygge din muskelmasse. Derfor gælder
          det om at prioritere den proteinrige kost. Tun, kylling, bønner,
          linser og broccoli er blot nogle af de madvarer, der indeholder masser
          af protein. Er du seriøs omkring din træning, er det dog en god idé at
          følge en kostplan så du hele tiden sørger for at din krop har
          brændstof til træningen.
        </p>
        <h2 className="text-2xl font-bold mt-4">
          Hvorfor har musklerne brug for protein?
        </h2>{" "}
        <p>
          Når du træner styrke vil kroppen i første omgang begynde at tære på
          dit blodsukker, det er det, som der med et fint ord hedder
          glykongedepoterne. Dernæst vil kroppen tage fat på dine muskler og
          fedtdepoter. Det er ikke ligefrem denne udvikling du ønsker at fremme
          ved træningen. Derfor er det vigtigt, at du optimerer dit
          proteinindtag så din krop har det protein den skal bruge til at
          producere aminosyrer, som er det kroppen kan bruge til
          muskelgenopbygningen. <br />
          Vil du gerne booste din muskelopbygning, er det derfor en god idé at
          tilføre ekstra proteiner gennem kosttilskud. Det er særligt lige efter
          træning, at du skal sørge for at indtage proteiner hvis du vil have
          det maksimale ud af træningen i forhold til at opbygge muskelmassen.
          Det er her, at kroppen har brug for næringsstoffer. Det er dog de
          færreste, der har mulighed for at tilberede et proteinrigt måltid i
          forlængelse af træningen. Netop derfor er et kosttilskud i form af
          proteinpulver ofte en rigtig god løsning. Med et proteintilskud
          tilfører du din krop det rette brændstof til, at du kan få det
          optimale ud af anstrengelserne i træningslokalet og du giver samtidig
          din krop de bedste vilkår til at opbygge muskelmasse.
        </p>
        <h2 className="text-2xl font-bold mt-4">Hvad er proteinpulver?</h2>{" "}
        <p>
          Proteinpulver findes i flere udgaver. Det mest populære og billigste
          proteinpulver er proteinpulver, der er fremstillet af som et biprodukt
          fra komælk. Denne type proteinpulver hedder valleproteinpulver. Du kan
          købe valleproteinpulver med forskellig proteinkoncentration.
          Valleprotein egner sig fortrinligt til at indtage lige efter
          træningen, da det optages meget hurtigt i kroppen. Valleprotein fås
          ofte i mange smagsvarianter, så du kan finde nøjagtig den smagsvariant
          du synes bedst om. Typisk fås proteinpulver med kakaosmag, bærsmag,
          vaniljesmag mv. Følg anvisningerne på pakken og bland pulveret med
          vand eller mælk i en shaker eller en drikkedunk når du er færdig med
          dagens træning. Der findes dog også andre typer af proteinprodukter
          end valleprotein. En anden type af proteinpulver der skal nævnes, er
          kasein proteinpulver. Kasein er ligeledes et stof, der findes i mælk.
          Forskellen på valleprotein er dog, at kasein optages langsomt i
          kroppen. Det betyder at kasinprotein egner sig bedre, som et
          kosttilskud du indtager på andre tidspunkter i løbet af dagen og ikke
          umiddelbart efter træning.
          <br />
          Er du veganer eller har du blot ikke lyst til at indtage
          proteinpulver, der er baseret på animalske produkter findes der også
          alternativer til valle- og kasein protein. Disse produkter er som
          regel fremstillet af ris eller sojaprotein. Der er derfor også
          proteinalternativer, selvom du ikke spiser kød eller produkter fra
          dyr.
        </p>
        <h2 className="text-2xl font-bold mt-4">
          Restitution og rigtig træning er vigtigt
        </h2>
        <p>
          Foruden den rigtige træning og den rette kost, er der endnu et
          element, som du skal have styr på for at opnå de bedste resultater fra
          din styrketræning. Du skal sørge for, at få restitution mellem dine
          træningssessioner. Samtidig gælder det om at få en god nattesøvn. Hvis
          du sover dårligt eller ikke restituerer dine muskler, får du ikke den
          optimale muskelvækst. Derfor skal rådet lyde, at du skal sørge for at
          sove godt og gerne 8 timer hver nat, samtidig skal du sørge for at der
          er pauser mellem dine træningssessioner. Det er en god idé at følge et
          træningsprogram, så ved du hvornår du skal træne hvilke muskelgrupper
          og hvornår du har hviledag. Er du nybegynder indenfor styrketræning,
          er det samtidig en god idé at lave en aftale med en instruktør eller
          en personlig træner. På den måde kan du få korrekt instruktion i
          hvordan du skal udføre øvelserne så du undgår skader. Fordelen ved at
          have en personlig træner er dog også, at du kan få et skræddersyet
          styrkeprogram, der passer nøjagtig til dig og din krop.
        </p>
        <h2 className="text-2xl font-bold mt-4">
          Køb kosttilskud og proteinpulver på nettet
        </h2>
        <p>
          De flest køber proteinpulver online på nettet. Der findes rigtig mange
          gode webbutikker at vælge imellem og fordelen er, at der ofte er mange
          penge at spare ved at købe proteinpulver i større mængder frem for at
          købe en enkelt proteinshake af gangen nede i træningscentret. Her på
          siden har vi samlet et udvalg af forhandlere, som vi anbefaler. <br />
          Vi ønsker dig held og lykke med din træning!
        </p>
      </div>
      <div className="mt-4 leading-8 p-4">
        <h2 className="text-2xl font-bold mt-4">Billigform.dk</h2>
        <p>
          I samarbejde med flere af landets forhandlere, at sportsernæring,
          sportsudstyr og sportstøj, er vores formål, at gøre det nemmere for
          dig, at finde det du søger til netop din træning. Vi vil hjælpe dig
          med at finde de bedste priser - på de bedste produkter. Billigform.dk
          er ikke en webshop og ejer deraf ikke produkterne. Du sendes derfor
          videre til forhandleren indefra Billigform.dk.
        </p>
      </div>
    </>
  );
};

export default IndexPage;
